import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";
import getBrowserFingerprint from "get-browser-fingerprint";
import instance from "./Components/common/Api";
import { useState } from "react";

// const [sentFcm, setSentFcm] = useState(true);

const firebaseConfig = {
  // apiKey: "AIzaSyBY-ZrueBktt4Ku6Yrt4lSZmDKub-zBk-o",
  // authDomain: "olaple-demo.firebaseapp.com",
  // projectId: "olaple-demo",
  // storageBucket: "olaple-demo.appspot.com",
  // messagingSenderId: "295885814777",
  // appId: "1:295885814777:web:3f9678400352ec7ece1b28",

  apiKey: "AIzaSyBk8F2Kqqwkz5mW9S5Y8Onpe_MDdTaYNpg",
  authDomain: "abethu-3ef62.firebaseapp.com",
  projectId: "abethu-3ef62",
  storageBucket: "abethu-3ef62.appspot.com",
  messagingSenderId: "323091654423",
  appId: "1:323091654423:web:55b20bf270194c8f433952",
  measurementId: "G-0Y2HBT13Y9",
};

// console.log("*** Firebase Config ***", firebaseConfig);

const firebaseApp = initializeApp(firebaseConfig);
// const messaging = getMessaging(firebaseApp);
export const messaging = getMessaging(firebaseApp);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  // console.warn(permission);
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BGU2tOrFVPFgmbDQGZo0sH-MIO1t1iCcc6DsnbkJ2zJaEW7aJMTjzB7c7FecTu93MTGhkOpzLr16egFhjoMqAMc",
    });
    console.log(token, "TOkn");
    localStorage.setItem("NewfcmOlaple", JSON.stringify(token));
    return token;
  }
};

export default firebaseApp;

// export const updateNotification = async (token) => {
//   instance
//     .post(`/saveUnauthUsersDeviceDetails?id=1&FCM-TOKEN=${token}`)
//     .then((response) => {
//       console.warn(response.data);
//     })
//     .catch((error) => {
//       console.error("Error fetching data:", error);
//     })
//     .finally(() => {});
// };
