import React, { useRef } from "react";
import { useState } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { APP_COLORS, APP_IMAGES } from "../common/Api";

const ShortFilms = ({ data, isMobile }) => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const navigate = useNavigate();

  const handleClick = (item) => {
    if (isMobile) {
      navigate("/play", { state: { someData: item } });
    } else {
      navigate("/media/", { state: { someData: item } });
    }
  };
  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const listRef = useRef(null);

  const scrollLeft = () => {
    if (listRef.current) {
      listRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (listRef.current) {
      listRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };
  const handleMoreClick = () => {
    //console.log("clicked");
    navigate("/showmore", {
      state: { Data: data, title: "SHORT FILMS", url_title: "short film" },
    }); // },
  };
  return (
    <div style={{ marginLeft: "1.5rem" }}>
      <Row
        className="align-items-center"
        style={{ height: isMobile ? "35px" : "50px" }}
      >
        <Col xs={6}>
          {isMobile ? (
            <h4
              style={{
                color: "#008dc4",
                marginBottom: "-15px",
                marginLeft: "-5px",
              }}
            >
              SHORT FILMS
            </h4>
          ) : (
            <h4
              style={{
                color: "#008dc4",
                marginBottom: "-15px",
                marginLeft: "12px",
              }}
            >
              SHORT FILMS
            </h4>
          )}
        </Col>
        <Col
          xs={6}
          className="d-flex justify-content-end"
          style={{ paddingRight: "20px" }}
        >
          {isMobile ? (
            <>
              <Button
                // variant="outline-light"
                style={{
                  backgroundColor: "transparent",
                  color: APP_COLORS.APP_DARK_2,
                  marginBottom: "-5px",
                  marginRight: "0px",
                  borderWidth: "0px",
                }}
                // disabled // Disable the button
                onClick={handleMoreClick}
              >
                More
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="dark"
                className="iconbtns back-0 rounded-circle"
                onClick={scrollLeft}
                style={{
                  marginRight: "5px",
                  border: "3px solid #655e5ea1",
                  backgroundColor: APP_COLORS.APP_DARK_1,
                }}
              >
                &lt;
              </Button>
              <Button
                variant="dark"
                className="iconbtns back-0 rounded-circle"
                onClick={scrollRight}
                style={{
                  border: "3px solid #655e5ea1",
                  backgroundColor: APP_COLORS.APP_DARK_1,
                }}
              >
                &gt;
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col
          xs={10}
          className="movie-list-container"
          style={isMobile ? { marginLeft: "-20px" } : null}
        >
          {isMobile ? (
            <div ref={listRef} className="movie-list">
              {data.map((item, index) => (
                <Card
                  key={index}
                  className={` ${
                    isMobile ? "movie-item-latest-mobile" : "movie-item"
                  } ${hoveredCard === index ? "zoomed-card" : ""}`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => handleClick(item)}
                >
                  <Card.Img
                    variant="top"
                    src={isMobile ? item.browse_image : item.mobile_image}
                    onError={(e) => {
                      e.target.src = isMobile
                        ? APP_IMAGES.PLACE_HORIZ_IMG //"./cineuns_horiz.png"
                        : APP_IMAGES.PLACE_HORIZ_IMG;
                    }}
                    style={
                      isMobile
                        ? {
                            objectFit: "cover",
                            width: "8rem",
                            // height: "auto",
                            maxHeight: "100%",
                            aspectRatio: "9 / 16", // Maintain 16:9 aspect ratio
                            borderRadius: "8px",

                            // objectFit: "cover",
                            // width: "100%",
                            // height: "auto",
                            // maxHeight: "100%",
                            // aspectRatio: "9 / 16", // Maintain 16:9 aspect ratio
                            // borderRadius: "8px",
                          }
                        : { height: "10rem" }
                    }
                  />
                  <p style={{ fontSize: "13px", color: APP_COLORS.APP_DARK_2 }}>
                    {item.title}
                  </p>
                </Card>
              ))}
            </div>
          ) : (
            <div ref={listRef} className="movie-list">
              {data.mediaContentList.map((item, index) => (
                <Card
                  key={index}
                  className={` ${
                    isMobile ? "movie-item-latest-mobile" : "movie-item"
                  } ${hoveredCard === index ? "zoomed-card" : ""}`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => handleClick(item)}
                >
                  <Card.Img
                    variant="top"
                    src={isMobile ? item.browse_image : item.mobile_image}
                    onError={(e) => {
                      e.target.src = isMobile
                        ? APP_IMAGES.PLACE_HORIZ_IMG
                        : APP_IMAGES.PLACE_HORIZ_IMG;
                    }}
                    style={
                      isMobile
                        ? {
                            objectFit: "cover",
                            width: "8rem",
                            // height: "auto",
                            maxHeight: "100%",
                            aspectRatio: "9 / 16", // Maintain 16:9 aspect ratio
                            borderRadius: "8px",

                            // objectFit: "cover",
                            // width: "100%",
                            // height: "auto",
                            // maxHeight: "100%",
                            // aspectRatio: "9 / 16", // Maintain 16:9 aspect ratio
                            // borderRadius: "8px",
                          }
                        : { height: "10rem" }
                    }
                  />
                  <p style={{ fontSize: "13px", color: APP_COLORS.APP_DARK_2 }}>
                    {item.title}
                  </p>
                </Card>
              ))}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ShortFilms;
