import React, { useEffect, useState } from "react";
import { APP_COLORS, APP_IMAGES } from "../common/Api";

const DeleteUserPage = (props) => {
  const { isMobile } = props;

  return (
    <div
      className="container-fluid privacyPolicyImgbox"
      style={{
        // width: "100%",
        minHeight: "55rem",
        position: "relative",
        backgroundImage: isMobile
          ? `url(${APP_IMAGES.APP_MAIN_BG_MOB})`
          : `url(${APP_IMAGES.APP_MAIN_BG_WEB})`,
        backgroundSize: "cover",
        // backgroundPosition: "center",
        // backgroundColor: 'transperent',
        backgroundBlendMode: "overlay",
      }}
    >
      <section style={{}}>
        <div className="container-fluid">
          <div className="container refundBellowTextBox">
            <div className="row firstRow">
              <div className="col-md-12">
                <h2 style={{ color: "rgb(0, 124, 194)" }}>
                  Delete User Policy
                </h2>
                <p
                  className="refundNoteText"
                  style={{ color: "rgb(0, 124, 194)" }}
                >
                  What happens if I permanently delete my User account?
                </p>
                <p
                  className="refundtext"
                  style={{ color: APP_COLORS.APP_DARK_2 }}
                >
                  You won't be able to reactivate your account
                </p>
                <p
                  className="refundtext"
                  style={{ color: APP_COLORS.APP_DARK_2 }}
                >
                  Your profile, photos and everything else you've added will be
                  permanently deleted. You won't be able to retrieve anything
                  you've added.
                </p>
              </div>
            </div>

            <div className="row SecondRow">
              <div className="col-md-12">
                <h2
                  className="refundHeading2"
                  style={{ color: "rgb(0, 124, 194)" }}
                >
                  How to Delete My User Account?
                </h2>
                <p
                  className="refundtext"
                  style={{ color: APP_COLORS.APP_DARK_2 }}
                >
                  You can send an Email to deletemyaccount@abethu.com with your
                  UserID or UID Details.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DeleteUserPage;
